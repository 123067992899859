import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

function ColorEdit(props) {
  return (
    <Edit title="Edit a Color" {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="title" fullWidth />
        <ColorInput source="color" picker="Sketch"/>
      </SimpleForm>
    </Edit>
  );
}

export default ColorEdit;
