import React, { useState, useEffect, Fragment } from "react";
import BamofySlider from "../../components/BamofySlider/BamofySlider";
import BamofyContent from "../../components/BamofyContent/BamofyContent";
import VerticalSlider from "../../components/VerticalSlider/VerticalSlider";
// import Timeline from "../../components/Timeline/Timeline";
// import TimelineBody from "../../components/TimelineBody/TimelineBody";
// import { TimelineContext } from "../../contexts/timeline";

import "../../style/main.scss";

function TimelinePage(data) {
  // const [context, setContext] = useState(basicData[0]);
  const [selectedWeek, setSelectedWeek] = useState(null);

  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  const basicData = data.data.months;

  useEffect(() => {
    if (basicData && basicData.length > 0) {
      const firstMonth = basicData[0];
      if (firstMonth.weeks && firstMonth.weeks.length > 0) {
        const firstWeek = firstMonth.weeks[0];
        setSelectedWeek({
          monthTitle: firstMonth.month_title,
          title: firstWeek.title,
          content: firstWeek.content,
          leap: firstWeek.leap,
          leap_intensity: firstWeek.leap_intensity,
          leap_description: firstWeek.leap_description,
          period: firstWeek.period,
          development: firstWeek.development,
          sleep_regression_text: firstWeek.sleep_regression_text,
        });
      }
    }
  }, [basicData]);

  const [activeWeek, setActiveWeek] = useState(null);
  const [activeMonth, setActiveMonth] = useState(null);

  const handleWeekClick = (
    monthTitle,
    title,
    content,
    leap,
    leap_intensity,
    period,
    leap_description,
    development,
    subtitle,
    sleep_regression_text
  ) => {
    setSelectedWeek({
      monthTitle,
      title,
      content,
      leap,
      leap_intensity,
      period,
      leap_description,
      development,
      subtitle,
      sleep_regression_text,
    });
    setActiveWeek(title);
    setActiveMonth(monthTitle);
  };
  return (
    <Fragment>
      {/* Improvement for future */}
      {/* <TimelineContext.Provider value={{context, setContext}}>
        <Timeline data={data} />
        <TimelineBody />
      </TimelineContext.Provider> */}

      <BamofySlider
        data={data}
        activeWeek={activeWeek}
        activeMonth={activeMonth}
        onContentChange={handleWeekClick}
        setFirstSwiper={setFirstSwiper}
        secondSwiper={secondSwiper}
      />
      <div className="bamofy_body">
        <VerticalSlider
          data={data}
          activeWeek={activeWeek}
          activeMonth={activeMonth}
          onWeekClick={handleWeekClick}
          setSecondSwiper={setSecondSwiper}
          firstSwiper={firstSwiper}
        />
        {selectedWeek && (
          <BamofyContent
            monthTitle={selectedWeek.monthTitle}
            title={selectedWeek.title}
            content={selectedWeek.content}
            leap={selectedWeek.leap}
            leap_intensity={selectedWeek.leap_intensity}
            period={selectedWeek.period}
            leap_description={selectedWeek.leap_description}
            development={selectedWeek.development}
            sleep_regression_text={selectedWeek.sleep_regression_text}
          />
        )}
      </div>
    </Fragment>
  );
}

export default TimelinePage;
