import React, { useState, useEffect } from "react";
import "./BamofyContent.scss";
import { Collapse } from "@material-ui/core";
import sanitizeHtml from 'sanitize-html';

function DevelopmentItem({ item }) {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  const truncatedDescription = item.description.slice(0, 100);
  const hasTruncatedDescription = item.description.length > 100;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const content = expanded
    ? item.description
    : isMobile
    ? truncatedDescription
    : item.description;

  return (
    <li className={"level_" + item.category}>
      <h3>{item.title}</h3>
      <p
        className={expanded ? "expanded" : ""}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {hasTruncatedDescription && isMobile && (
        <span className="show-more" onClick={handleAccordionToggle}>
          {expanded ? "Read Less" : "Read More"}
        </span>
      )}
      <Collapse in={expanded}></Collapse>
    </li>
  );
}

function BamofyContent({
  monthTitle,
  title,
  sleep_regression_text,
  content,
  period,
  leap,
  leap_intensity,
  leap_description,
  development,
}) {
  const extractNumber = (title) => {
    const regex = /\d+/;
    const matches = title.match(regex);
    return matches ? matches[0] : "";
  };

  const numberOnly = extractNumber(monthTitle);

  function renderSafeHTML(htmlString) {
    return { __html: sanitizeHtml(htmlString) };
  }

  return (
    <div className="content">
      <div className="content-header">
        <div className="content-header__title">
          <div className="title-wrap">
            <h2>{numberOnly}</h2>
            .
            <h3>{title}</h3>
          </div>
        </div>
        <div className="content-header__body">
          {/* {leap_intensity === "1" && (
            <div className="leaps">
              <p className="leap"></p>
              <span>Leap {leap}</span>
            </div>
          )}
          {leap_intensity === "2" && (
            <div className="leaps">
              <p className="leap"></p>
              <p className="leap"></p>
              <span>Leap {leap}</span>
            </div>
          )}
          {leap_intensity === "3" && (
            <div className="leaps">
              <p className="leap"></p>
              <p className="leap"></p>
              <p className="leap"></p>
              <span>Leap {leap}</span>
            </div>
          )} */}
          <h4
            style={{
              color: period === "LESS IMPORTANT PERIOD" ? "#18A447" : "#FF800B",
            }}
          >
            {period}
          </h4>
        </div>
      </div>
      {leap_description && <p className="leap_description" dangerouslySetInnerHTML={renderSafeHTML(leap_description)} />}
      
      {sleep_regression_text && (
        <p className="sleep_regression">{sleep_regression_text}</p>
      )}
      {content && <div className="content_body" dangerouslySetInnerHTML={renderSafeHTML(content)} />}

      {development && development.length > 0 && (
        <ul className="content-development">
          {development.map((item, index) => (
            <DevelopmentItem key={index} item={item} />
          ))}
        </ul>
      )}
    </div>
  );
}

export default BamofyContent;
