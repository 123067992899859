import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

function MainEdit(props) {
  return (
    <Edit title="Edit main title / subtitle" {...props}>
      <SimpleForm fullWidth>

        <TextInput source="hero_title" fullWidth />
        <TextInput multiline source="hero_subtitle"  fullWidth/>
        <TextInput source="main_title" fullWidth />
        <TextInput multiline source="main_subtitle"  fullWidth/>
      </SimpleForm>
    </Edit>
  );
}

export default MainEdit;
