import React from "react";
import { List, Datagrid, TextField, EditButton} from "react-admin";

function MainList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField  source='hero_title'/>
        <TextField  source='hero_subtitle'/>
        <TextField  source='main_title'/>
        <TextField  source='main_subtitle'/>
        <EditButton basepath='/main' />
      </Datagrid>
    </List>
  );
}

export default MainList;
