import React from 'react'
import Hero from '../../components/Hero/Hero';
import Description from '../../components/Description/Description';

function HeroPage(data) {
  return (
    <div>
        <Hero data={data}/>
        <Description data={data}/>
    </div>
  )
}

export default HeroPage