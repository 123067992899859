import React, { Fragment, useState, useEffect } from "react";
import { fetchUtils, Admin, Resource } from "react-admin";
import jsonServerProvider from "ra-data-json-server";

import HeaderLinksList from "./Header/HeaderLinksList";
import HeaderLinksEdit from "./Header/HeaderLinksEdit";

import MonthList from "./MonthList";
import MonthCreate from "./MonthCreate";
import MonthEdit from "./MonthEdit";

import ColorEdit from "./Colors/ColorEdit";
import ColorList from "./Colors/ColorList";
import ColorCreate from "./Colors/ColorCreate";

import MainEdit from "./MainEdit";
import MainList from "./MainList";

import CardCreate from "./Cards/CardCreate";
import CardEdit from "./Cards/CardEdit";
import CardList from "./Cards/CardList";

import AdsList from "./Ads/AdsList";
import AdsCreate from "./Ads/AdsCreate";
import AdsEdit from "./Ads/AdsEdit";

import AboutList from "./About/AboutList";
import AboutEdit from "./About/AboutEdit";

import ContactEdit from "./Contact/ContactEdit";
import ContactList from "./Contact/ContactList";

import authProvider from "./authProvider";
import BamofyLoginPage from "./BamofyLoginPage";

import FooterEdit from "./Footer/FooterEdit";
import FooterList from "./Footer/FooterList";

import BackupButton from "../Backup/Backup";

import "./AdminPanels.scss";

function AdminPanel() {
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }

    options.headers.set("X-Custom-Header", "foobar");
    options.headers.set("Content-Range", "posts 0-20/20");
    options.headers.set("X-Total-Count", "results.total");
    options.user = {
      authenticated: true,
      token: "SRTRDFVESGNJYTUKTYTHRG",
    };
    return fetchUtils.fetchJson(url, options);
  };

  const dataProvider = jsonServerProvider(
    "https://db-dgf3.onrender.com",
    httpClient
  );

  // const dataProvider = jsonServerProvider("http://localhost:3000");

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await authProvider.checkAuth();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  return (
    <Fragment>
      {isAuthenticated && <BackupButton />}
      <Admin
        basename="/panel-bam-admin"
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={BamofyLoginPage}
      >
        <Resource
          name="header_links"
          list={HeaderLinksList}
          edit={HeaderLinksEdit}
        />

        <Resource
          name="months"
          list={MonthList}
          create={MonthCreate}
          edit={MonthEdit}
          options={{ defaultSort: { field: "month_title", order: "ASC" } }}
          perPage={10}
        />

        <Resource
          name="colors"
          list={ColorList}
          edit={ColorEdit}
          create={ColorCreate}
        />

        <Resource name="main_text" list={MainList} edit={MainEdit} />

        <Resource
          name="cards"
          list={CardList}
          edit={CardEdit}
          create={CardCreate}
        />

        <Resource name="about_text" list={AboutList} edit={AboutEdit} />

        <Resource name="contact" list={ContactList} edit={ContactEdit} />

        <Resource
          name="google_ads"
          list={AdsList}
          edit={AdsEdit}
          create={AdsCreate}
        />

        <Resource name="footer" list={FooterList} edit={FooterEdit} />
      </Admin>
    </Fragment>
  );
}

export default AdminPanel;
