import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

function MonthCreate(props) {
  return (
    <Create title="Create a month" {...props}>
        <SimpleForm>
          <span>Please Write month title: ex. MONTH 1</span>
            <TextInput source='month_title' />
            <TextInput multiline source='subtitle' />
        </SimpleForm>
    </Create>
  )
}

export default MonthCreate