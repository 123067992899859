import React from 'react'
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';

function ColorList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source='id' />
        <TextField source='title' />
        <TextField source='color' />
        <EditButton basepath='/colors' />
        <DeleteButton basepath='/colors' />
      </Datagrid>
    </List>
  )
}

export default ColorList