import React, { useRef, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ContactForm.scss";
import { CircularProgress } from "@material-ui/core";
import ContactImg from "../../img/contact3.png";

const ContactForm = (data) => {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const form = useRef();

  const clearForm = () => {
    form.current.reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLICK_KEY
      )
      .then((result) => {
        toast.success("Email has been sent!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setIsSent(true);
        clearForm();
      })
      .catch((error) => {
        console.log(error.text);
        toast.error("Failed to send email!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const contactData = data.data.data.contact[0];

  return (
    <>
      <div className="form-section">
        <div className="block">
          <h3>{contactData.contact_title}</h3>
          <p>
            {contactData.contact_text}
          </p>
          <form ref={form} onSubmit={sendEmail}>
            <div>
              <TextField label="Name" name="user_name" required />
            </div>

            <div>
              <TextField
                label="Email"
                name="user_email"
                type="email"
                required
              />
            </div>

            <div>
              <TextField
                label="Message"
                name="message"
                multiline
                minRows={5}
                required
              />
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSending || isSent} // Disable the button when sending or after successful submission
            >
              {isSending ? <CircularProgress size={24} /> : isSent ? "Sended" : "Submit"}
            </Button>
          </form>
        </div>
        <div className="block-img">
          <img src={ContactImg} alt="contact" />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ContactForm;
