import React, { Fragment, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import GoogleAdContainer from "./components/GoogleAdContainer/GoogleAdContainer";
import { CircularProgress } from "@material-ui/core";
import "./style/main.scss";

import routes from "./routes/routes";

function App() {
  const isPathAdmin = window.location.pathname.startsWith("/panel-bam-admin");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          fetch("https://db-dgf3.onrender.com/header_links"),
          fetch("https://db-dgf3.onrender.com/months"),
          fetch("https://db-dgf3.onrender.com/main_text"),
          fetch("https://db-dgf3.onrender.com/cards"),
          fetch("https://db-dgf3.onrender.com/about_text"),
          fetch("https://db-dgf3.onrender.com/contact"),
          fetch("https://db-dgf3.onrender.com/footer"),
          fetch("https://db-dgf3.onrender.com/colors"),
          fetch("https://db-dgf3.onrender.com/google_ads"),
          // fetch("http://localhost:3000/header_links"),
          // fetch("http://localhost:3000/months"),
          // fetch("http://localhost:3000/main_text"),
          // fetch("http://localhost:3000/cards"),
          // fetch("http://localhost:3000/about_text"),
          // fetch("http://localhost:3000/contact"),
          // fetch("http://localhost:3000/footer"),
          // fetch("http://localhost:3000/colors"),
          // fetch("http://localhost:3000/google_ads"),
        ]);

        const responseData = await Promise.all(
          responses.map((response) => response.json())
        );

        const combinedData = responseData.reduce(
          (accumulator, currentData, index) => {
            const key = responses[index].url.split("/").pop();
            return { ...accumulator, [key]: currentData };
          },
          {}
        );

        setData(combinedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Fragment>
    {loading ? (
      <div className="custom-loader">
        <CircularProgress className="loader" />
      </div>
    ) : isPathAdmin ? (
      <Routes>
        <Route path="/panel-bam-admin/*" element={<AdminPanel />} />
      </Routes>
    ) : (
      <div className="App container">
        <Header data={data} />
        <Routes>
          {routes.map(route => (
            <Route key={route.path} path={route.path} element={<route.component data={data} />} />
          ))}
        </Routes>
        <GoogleAdContainer data={data} />
        <Footer data={data} />
      </div>
    )}
  </Fragment>
  );
}

export default App;
