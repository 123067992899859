import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin';

function AdsCreate(props) {
  return (
    <Create title="Create a Google Ads" {...props}>
    <SimpleForm fullWidth>
        <TextInput source='google_ads_title' />
        <TextInput source='data_ad_client' />
        <TextInput source='data_ad_slot' />
    </SimpleForm>
</Create>
  )
}

export default AdsCreate