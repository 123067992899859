import React from 'react'
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';

function AdsList(props) {
  return (
    <List {...props}>
    <Datagrid>
      <TextField source='id' />
      <TextField source='google_ads_title' />
      <TextField source='data_ad_client' />
      <TextField source='data_ad_slot' />
      <EditButton basepath='/google_ads' />
      <DeleteButton basepath='/google_ads' />
    </Datagrid>
  </List>
  )
}

export default AdsList