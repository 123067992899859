import React from "react";
import "./AboutPage.scss";

import AboutImg from "../../img/about.svg";

function AboutPage(data) {
  const aboutData = data.data.about_text[0];

  return (
    <section className="about">
      <div className="about-text">
        <h2>Who we are?</h2>
        <p dangerouslySetInnerHTML={{ __html: aboutData.about_title }} />
        <p dangerouslySetInnerHTML={{ __html: aboutData.about_subtitle }} />
        <p dangerouslySetInnerHTML={{ __html: aboutData.about_footer }} />
      </div>

      <div className="about-image">
        <img src={AboutImg} alt="About" />
      </div>

      {/* <div className="about-blocks">
    {aboutData.about_pros.map((item, index) => (
        <div className="about-block" key={index} dangerouslySetInnerHTML={{ __html: item.pros_text }} />
      ))}
    </div> */}
    </section>
  );
}

export default AboutPage;
