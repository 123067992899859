import React from "react";
import { NavLink } from "react-router-dom";
import logoImage from "../../img/bamofy_logo.svg";
import "./Footer.scss";

function Footer(data) {
  const footerData = data.data.footer[0];
  
  return (
    <footer className="footer">
      <div className="logo">
        <NavLink to="/">
          <img src={logoImage} alt="Bamofy" />
        </NavLink>
      </div>
      <div className="footer-block">
        <h2>{footerData.footer_contact_title}</h2>
        <a href={`mailto:${footerData.footer_email}`}>
          {footerData.footer_email}
        </a>
        <a href={`tel:${footerData.footer_phone}`}>
          {footerData.footer_phone}
        </a>
      </div>
      <div className="footer-block">
        <h2>{footerData.footer_location_title}</h2>
        <p>{footerData.footer_address}</p>
        <p>{footerData.footer_location}</p>
      </div>
      <div className="footer-bottom">
        <p>{footerData.footer_copyright}</p>
        <div className="social">
          {footerData.footer_socials.map((item, index) => (
            <a
              aria-label="social-link"
              key={index}
              href={item.social_link}
              target="_blank"
              rel="noreferrer"
            >
              <i className={item.social_icon}></i>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
