import React, { forwardRef, useEffect, useState } from "react";
import "./VerticalSlider.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Controller, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/mousewheel";

const VerticalSlider = forwardRef(
  (
    {
      activeWeek,
      activeMonth,
      onWeekClick,
      setSecondSwiper,
      firstSwiper,
      data,
    },

    ref
  ) => {
    const verticalData = data.data;

    const handleWeekClick = (
      monthTitle,
      weekTitle,
      weekContent,
      leap,
      leap_intensity,
      period,
      leap_description,
      development,
      sleep_regression,
      sleep_regression_text
    ) => {
      onWeekClick(
        monthTitle,
        weekTitle,
        weekContent,
        leap,
        leap_intensity,
        period,
        leap_description,
        development,
        sleep_regression,
        sleep_regression_text
      );
    };

    const [isHorizontal, setIsHorizontal] = useState(window.innerWidth > 991);

    useEffect(() => {
      const handleResize = () => {
        setIsHorizontal(window.innerWidth < 992);
      };
  
      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <div className="vertical-slider">
        <Swiper
          direction={isHorizontal ? "horizontal" : "vertical"}
          mousewheel={true}
          slidesPerView={3}
          spaceBetween={15}
          loop={false}
          freeMode={true}
          autoHeight={true}
          modules={[FreeMode, Controller, Mousewheel]}
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
          className="vertical-wrapper vertical-track"
          ref={ref}
          breakpoints={{
            992: {
              slidesPerView: 5,
            },
          }}
        >
          {verticalData.months.map((month, monthIndex) => (
            <SwiperSlide
              key={monthIndex}
              className={
                month.month_title === "1 MONTH" &&
                month.subtitle === "baby's life"
                  ? "vertical-birth"
                  : ""
              }
            >
              <h3
                className={
                  month.month_title === activeMonth ? "active-month" : ""
                }
              >
                {month.month_title}
              </h3>
              <div>
                {month.weeks &&
                  month.weeks.map((week, weekIndex) => (
                    <div key={weekIndex}>
                      <h5
                        className={`v-week ${
                          week.title === activeWeek ? "active-week" : ""
                        }`}
                        onClick={() =>
                          handleWeekClick(
                            month.month_title,
                            week.title,
                            week.content,
                            week.leap,
                            week.leap_intensity,
                            week.period,
                            week.leap_description,
                            week.development,
                            week.sleep_regression,
                            week.sleep_regression_text
                          )
                        }
                      >
                        {week.title} Week
                        {week.leap_intensity === "1" && (
                          <span className="v-leap"></span>
                        )}
                        {week.leap_intensity === "2" && (
                          <div className="leaps">
                            <span className="v-leap"></span>
                            <span className="v-leap"></span>
                          </div>
                        )}
                        {week.leap_intensity === "3" && (
                          <div className="leaps">
                            <span className="v-leap"></span>
                            <span className="v-leap"></span>
                            <span className="v-leap"></span>
                          </div>
                        )}
                        {week.sleep_regression === 1 && (
                          <span className="v-sleep"></span>
                        )}
                      </h5>
                    </div>
                  ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
);

export default VerticalSlider;
