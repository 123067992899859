import React from 'react'
import './NotFound.scss';

function NotFound() {
  return (
    <div className='not-found'>
    <h1> Oops!</h1>
    <h2>Where are we?</h2>
    <p>The page you are looking for was moved, removed, renamed or might never existed</p>
    <a className='explore-btn' href="/">Go Home</a>
  </div>
  )
}

export default NotFound