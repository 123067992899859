import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

function AdsEdit(props) {
  return (
    <Edit title="Edit Google Ads" {...props}>
      <SimpleForm fullWidth>
        <TextInput disabled source="id" />
        <TextInput source="google_ads_title" />
        <TextInput source="data_ad_client" />
        <TextInput source="data_ad_slot" />
      </SimpleForm>
    </Edit>
  );
}

export default AdsEdit;
