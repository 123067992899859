import React from 'react';
import { List, Datagrid, TextField, ArrayField, SingleFieldList, ChipField, EditButton, DeleteButton } from 'react-admin';

function MonthList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source='id' />
        <TextField source='month_title' label='Month Title' />
        <TextField source='subtitle' label='Subtitle' />
        <ArrayField source='weeks'>
          <SingleFieldList>
            <ChipField source='title' />
          </SingleFieldList>
        </ArrayField>
        <EditButton basepath='/months' />
        <DeleteButton basepath='/months' />
      </Datagrid>
    </List>
  );
}

export default MonthList;
