import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

function ColorCreate(props) {
  return (
    <Create title="Create a color" {...props}>
        <SimpleForm fullWidth>
            <TextInput source='title' fullWidth/>
            <ColorInput source='color' picker="Sketch"/>
        </SimpleForm>
    </Create>
  )
}

export default ColorCreate