import React, { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { TextField, Button } from '@material-ui/core';

import './BamofyLoginPage.scss';
import logo from '../../img/bamofy_logo.svg';

function BamofyLoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password })
      .catch(() => notify('Invalid username or password'));
  };

  const handleLogoClick = () => {
    window.location.href = '/';

  };

  return (
    <div className='bamofy-login-wrapper'>
      <form className='bamofy-login-form' onSubmit={handleSubmit}>
            <img src={logo} alt="Bamofy" onClick={handleLogoClick}/>
            <TextField
                className='login-input'
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
                className='login-input'
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
            >
                Sign In
            </Button>
      </form>
    </div>
  );
}

export default BamofyLoginPage;
