import React from 'react'
import ContactForm from '../../components/ContactForm/ContactForm'

function ContactPage(data) {
  return (
    <div>
        
    <ContactForm data={data}/>
    </div>
  )
}

export default ContactPage