const authProvider = {
    login: ({ username, password }) => {
        if (username === process.env.REACT_APP_USERNAME && password === process.env.REACT_APP_PASSWORD) {
          
          localStorage.setItem('token', 'admin');
          return Promise.resolve();
          
        } else {
          return Promise.reject(new Error('Incorrect username or passwrod!'));
        }
      },
      logout: () => {
        localStorage.removeItem('token'); 
        return Promise.resolve();
      },
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        return Promise.reject(new Error('You are not authorized!'));
      }
      return Promise.resolve();
    },
    checkAuth: () => {
      return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
      return Promise.resolve({});
    },
  };
  
  export default authProvider;
  