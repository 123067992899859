import React from 'react';
import { Link } from 'react-router-dom';
import TimelineExample from '../../img/Timeline_example.png';
import './Hero.scss';

function Hero(data) {
    const mainText = data.data.data.main_text[0];
  return (
    <div className="hero">
        <div className="hero-block">
            <h1>{mainText.hero_title}</h1>
            <p>{mainText.hero_subtitle}</p>
            <div className="hero-btns">
                <Link to="/timeline">
                    <button className='explore-btn'>Explore now</button>
                </Link>
                <button className="read-more-btn"><a href="#description">Read more</a></button>
            </div>
        </div>

        <div className="hero-block">
            <img className='example-img' src={TimelineExample} alt="Timeline Exmaple" />
        </div>

    </div>
  )
}

export default Hero