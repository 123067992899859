import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput
  } from "react-admin";

function ContactEdit(props) {
  return (
    <Edit title="Contact Edit" {...props}>
      <SimpleForm fullWidth>
        <TextInput multiline source="contact_title" fullWidth />
        <TextInput multiline source="contact_text" fullWidth />
      </SimpleForm>
    </Edit>
  )
}

export default ContactEdit