import React, { useState } from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

function CardEdit(props) {
  const [iconClass, setIconClass] = useState("");

  const handleIconChange = (event) => {
    setIconClass(event.target.value);
  };
    
  return (
    <Edit title="Edit a Card" {...props}>
      <SimpleForm fullWidth>
        <TextInput disabled source="id" />

        <div className="icon-select">
          <TextInput
            source="card_icon"
            label="Font Awesome Icon Class"
            onChange={handleIconChange}
          />

          <i className={`${iconClass}`}></i>
        </div>

        <TextInput source="card_title" fullWidth />
        <TextInput multiline source="card_description" fullWidth />
      </SimpleForm>
    </Edit>
  );
}

export default CardEdit;
