import React from 'react'
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';

function CardList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source='id' />
        <TextField source='card_title' label='Card Title' />
        <TextField source='card_description' label='Card Description' />
        <EditButton basepath='/cards' />
        <DeleteButton basepath='/cards' />
      </Datagrid>
    </List>
  )
}

export default CardList