import React from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin';

function ContactList(props) {
  return (
    <List {...props}>
    <Datagrid>
      <TextField source='contact_title' />
      <TextField source='contact_text' />
      <EditButton basepath='/contact' />
    </Datagrid>
  </List>
  )
}

export default ContactList