import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
} from "@material-ui/core";
import "./descr_style.scss";

function Description(data) {
  const [isMobile, setIsMobile] = useState(false);

  const descriptionText = data.data.data.main_text[0];
  const descriptionCards = data.data.data.cards;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Card = ({ icon, title, content }) => {
    if (isMobile) {
      return (
        <Accordion className="card">
          <AccordionSummary
            expandIcon={<Icon className="fa-solid fa-chevron-down" />}
          >
            <span className="icon">
              <i className={icon}></i>
            </span>{" "}
            {title}
          </AccordionSummary>
          <AccordionDetails>{content}</AccordionDetails>
        </Accordion>
      );
    }

    return (
      <div className="card">
        <div className="card-header">
          <div className="icon">
            <i className={icon}></i>
          </div>
          <h3>{title}</h3>
        </div>
        <p>{content}</p>
      </div>
    );
  };

  return (
    <div className="description" id="description">
      <div className="description-header">
        <h2>{descriptionText.main_title}</h2>

        <p>{descriptionText.main_subtitle}</p>
      </div>

      <div className="description-cards">
        {descriptionCards.map((item,index)=>
          <Card key={index} item={item} icon={item.card_icon} title={item.card_title} content={item.card_description}/>
        )}
      </div>
    </div>
  );
}

export default Description;
