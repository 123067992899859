import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './Language.scss';

const useStyles = makeStyles((theme) => ({
  dropdownButton: {
    textTransform: 'none',
  },
}));

function Language() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('ET'); // Изначально выбранный элемент

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    handleClose();
  };

  return (
    <div className='language'>
      <Button
        className={classes.dropdownButton}
        aria-controls="language-dropdown"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {selectedLanguage}
      </Button>
      <Menu
        id="language-dropdown"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLanguageSelect('ET')}>ET</MenuItem>
        <MenuItem onClick={() => handleLanguageSelect('EN')}>EN</MenuItem>
        <MenuItem onClick={() => handleLanguageSelect('RU')}>RU</MenuItem>
      </Menu>
    </div>
  );
}

export default Language;
