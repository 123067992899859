import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin';

function HeaderLinksEdit(props) {
  return (
    <Edit title="Edit a Header Link text" {...props}>
      <SimpleForm fullWidth>
        <TextInput source='home'/>
        <TextInput source='about_us' />
        <TextInput source='timeline' />
        <TextInput source='contact_us' />
      </SimpleForm>
    </Edit>
  )
}

export default HeaderLinksEdit