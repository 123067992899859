import React from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin';

function AboutList(props) {
  return (
    <List {...props}>
    <Datagrid>
      <TextField source='about_title' label='About Title' />
      <TextField source='about_subtitle' label='About Description' />
      <EditButton basepath='/about' />
    </Datagrid>
  </List>
  )
}

export default AboutList