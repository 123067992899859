import React from "react";
import { Edit, SimpleForm, TextInput, ArrayInput, SimpleFormIterator } from "react-admin";

function FooterEdit(props) {
  return (
    <Edit title="Footer Edit" {...props}>
      <SimpleForm fullWidth>
      <TextInput source='footer_contact_title' fullWidth/>
      <TextInput source='footer_email' />
      <TextInput source='footer_phone' />
      <TextInput source='footer_location_title' fullWidth/>
      <TextInput source='footer_address' />
      <TextInput source='footer_location' />
      <TextInput source='footer_copyright' fullWidth/>

        <ArrayInput source="footer_socials" className="admin-weeks" label="">
          <SimpleFormIterator fullWidth className="admin-week">
            <TextInput
              source="social_link"
              fullWidth
            />
            <TextInput
              source="social_icon"
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
}

export default FooterEdit;
