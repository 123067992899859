import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  NumberInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDumbbell,
  faBed,
  faBowlFood,
  faComments,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

function MonthEdit(props) {
  return (
    <Edit title="Edit a Month" {...props}>
      <SimpleForm>
        <div className="admin-month">
          <TextInput disabled source="id" />
          <TextInput source="month_title" />
          <TextInput multiline source="subtitle" />
        </div>
        <ArrayInput source="weeks" className="admin-weeks">
          <SimpleFormIterator fullWidth className="admin-week">
            <NumberInput source="title" label="Week Number" />
            <RichTextInput
              source="content"
              fullWidth
              multiline
              label="Week Description"
            />

            <SelectInput
              source="period"
              choices={[
                { id: "LESS IMPORTANT PERIOD", name: "LESS IMPORTANT PERIOD" },
                { id: "VERY IMPORTANT PERIOD", name: "VERY IMPORTANT PERIOD" },
              ]}
            />

            <SelectInput
              source="sleep_regression"
              choices={[
                { id: 0, name: "no" },
                { id: 1, name: "yes" },
              ]}
            />

            <TextInput
              source="sleep_regression_text"
              fullWidth
              multiline
              label="Sleep Regression Text"
            />

            <TextInput source="leap" label="Leap" />
            <TextInput source="leap_intensity" label="Leap intensity" />

            <TextInput
              source="leap_header"
              fullWidth
              multiline
              label="Leap description"
            />

            <RichTextInput
              source="leap_description"
              fullWidth
              multiline
              label="Leap description"
            />

            {/* Adding Development Information */}
            <ArrayInput
              label=""
              source="development"
              className="admin-development"
            >
              <SimpleFormIterator fullWidth>
                <SelectInput
                  source="category"
                  choices={[
                    { id: "development", name: "Development" },
                    { id: "sleeping", name: "Sleeping" },
                    { id: "feeding", name: "Feeding" },
                    { id: "communication", name: "Communication" },
                    { id: "social", name: "Social" },
                  ]}
                  optionText={(choice) => (
                    <span>
                      <FontAwesomeIcon
                        className="level_icon"
                        icon={getFontAwesomeIcon(choice.id)}
                      />
                      {choice.name}
                    </span>
                  )}
                />
                <TextInput
                  source="title"
                  label="Development Title"
                  className="selected-category"
                />
                <RichTextInput
                  source="description"
                  fullWidth
                  multiline
                  label="Development Description"
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
}

function getFontAwesomeIcon(category) {
  switch (category) {
    case "development":
      return faDumbbell;
    case "sleeping":
      return faBed;
    case "feeding":
      return faBowlFood;
    case "communication":
      return faComments;
    case "social":
      return faUserGroup;
    default:
      return null;
  }
}

export default MonthEdit;
