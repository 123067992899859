// routes.js

import HeroPage from '../pages/HeroPage/HeroPage';
import TimelinePage from '../pages/TimelinePage/TimelinePage';
import AboutPage from '../pages/AboutPage/AboutPage';
import ContactPage from '../pages/ContactPage/ContactPage';
import NotFound from '../components/NotFound/NotFound';

const routes = [
  { path: '/', name: 'Home', component: HeroPage },
  { path: '/timeline', name: 'Timeline', component: TimelinePage },
  { path: '/about', name: 'About', component: AboutPage },
  { path: '/contact', name: 'Contact', component: ContactPage },
  { path: '*', name: 'NotFound', component: NotFound },
];

export default routes;
