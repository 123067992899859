import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin';

function CardCreate(props) {
  return (
    <Create title="Create a month" {...props}>
        <SimpleForm fullWidth>
            <TextInput source='card_icon'/>
            <TextInput source='card_title' fullWidth/>
            <TextInput multiline source='card_description' fullWidth/>
        </SimpleForm>
    </Create>
  )
}

export default CardCreate