import React, { useState } from 'react';
import axios from 'axios';

function BackupButton() {
  const [backupStatus, setBackupStatus] = useState(null);

  const createBackup = async () => {
    setBackupStatus('Making Backup...');

    try {
      // Create an object to store the data
      const data = {};

      // Array of JSON server endpoints
      const endpoints = [
        'header_links',
        'months',
        'main_text',
        'cards',
        'about_text',
        'contact',
        'footer',
        'colors',
        'google_ads',
      ];

      // Request data from each endpoint
      for (const endpoint of endpoints) {
        const response = await axios.get(`https://db-dgf3.onrender.com/${endpoint}`);
        data[endpoint] = response.data;
      }

      // Convert the data object to a JSON string
      const jsonData = JSON.stringify(data, null, 2);

      // Create and save the JSON file
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'backup.json';
      a.click();
      URL.revokeObjectURL(url);

      setBackupStatus('Backup created successfully!');
    } catch (error) {
      console.error('Error fetching data:', error);
      setBackupStatus('Error creating data backup.');
    }
  };

  return (
    <div className="backup-button-container">
      <button className="backup-button" onClick={createBackup}>Create Backup</button>
      <p className="backup-message">{backupStatus}</p>
    </div>
  );
}

export default BackupButton;
