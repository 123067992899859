import React, { Fragment, useEffect } from "react";

function GoogleAdContainer(data) {
  const google_ads_data = data.data.google_ads[0];

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <Fragment>
      {/* <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={google_ads_data.data_ad_client}
        data-ad-slot={google_ads_data.data_ad_slot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> */}
      

      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2197985161640529"
     crossOrigin="anonymous"></script>

      <ins className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-2197985161640529"
          data-ad-slot="3912361327"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </Fragment>
  );
}

export default GoogleAdContainer;
