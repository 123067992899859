import React from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin';


function FooterList(props) {
  return (
    <List {...props}>
    <Datagrid>
      <TextField source='footer_contact_title' />
      <TextField source='footer_email' />
      <TextField source='footer_phone' />
      <TextField source='footer_location_title' />
      <TextField source='footer_address' />
      <TextField source='footer_location' />
      <TextField source='footer_copyright' />
      <EditButton basepath='/footer' />
    </Datagrid>
  </List>
  )
}

export default FooterList