import React, { useState, forwardRef, useEffect } from "react";
import "./BamofySlider.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Controller } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

const BamofySlider = forwardRef(
  (
    { onContentChange, activeWeek, setFirstSwiper, secondSwiper, data },
    ref
  ) => {
    const colors = data.data.colors.map((colorObj) => colorObj.color);
    const timelineData = data.data;

    const [openMonth, setOpenMonth] = useState(null);
    const [openSub, setOpenSub] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
      const firstWeek = document.querySelector(".slider-content h5");

      if (firstWeek) {
        firstWeek.click();
      }
    }, []);

    const getInnerNavStyle = (index) => {
      const colorIndex = Math.floor(index / 5) % colors.length;
      const opacity = index % 5 === 0 ? 1 : 0.9 - (index % 5) * 0.1;
      const backgroundColor = `${colors[colorIndex]}${Math.round(
        opacity * 255
      ).toString(16)}`;
      return {
        backgroundColor,
      };
    };

    const getInnerTitleStyle = (index) => {
      const colorIndex = Math.floor(index / 5) % colors.length;
      const opacity = index % 5 === 0 ? 1 : 0.9 - (index % 5) * 0.1;
      const borderColor = `${colors[colorIndex]}${Math.round(
        opacity * 255
      ).toString(16)}`;
      return {
        borderColor,
      };
    };

    const getAfterSliderContent = (index) => {
      const colorIndex = Math.floor(index / 5) % colors.length;
      const opacity = index % 5 === 0 ? 1 : 0.9 - (index % 5) * 0.1;
      const borderColor = `${colors[colorIndex]}${Math.round(
        opacity * 255
      ).toString(16)}`;
      const borderBottom = `20px solid ${borderColor}`;
      return {
        borderBottom,
      };
    };

    const handleWeekClick = (
      monthTitle,
      title,
      content,
      leap,
      leap_intensity,
      period,
      leap_description,
      development,
      subtitle,
      sleep_regression,
      sleep_regression_text
    ) => {
      setOpenMonth(monthTitle);
      const currentIndex = timelineData.months.findIndex(
        (month) => month.month_title === monthTitle
      );

      setCurrentSlide(currentIndex);
      setOpenSub(subtitle);
      onContentChange(
        monthTitle,
        title,
        content,
        leap,
        leap_intensity,
        period,
        leap_description,
        development,
        sleep_regression,
        sleep_regression_text
      );
    };

    return (
      <div className="wrap">
        <div className="slider-wrapper">
          <p className="week">WEEK</p>
          <Swiper
            spaceBetween={0}
            slidesPerView={2}
            freeMode={true}
            modules={[FreeMode, Scrollbar, Controller]}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            className="horizontal-track"
            ref={ref}
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
            speed={200}
            scrollbar={{ draggable: true, hide: false }}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 5,
              },
            }}
          >
            {timelineData.months.map((month, monthIndex) => (
              <SwiperSlide key={monthIndex}>
                <div
                  className={`slider-content ${
                    openMonth === month.month_title ? "open" : ""
                  }`}
                >
                  <div className="inner-block">
                    <h3
                      className="inner_title"
                      style={getInnerTitleStyle(monthIndex)}
                      onClick={() =>
                        handleWeekClick(
                          month.month_title,
                          month.weeks[0].title,
                          month.weeks[0].content,
                          month.weeks[0].leap,
                          month.weeks[0].leap_intensity,
                          month.weeks[0].period,
                          month.weeks[0].leap_description,
                          month.weeks[0].development,
                          month.weeks[0].sleep_regression,
                          month.weeks[0].sleep_regression_text
                        )
                      }
                    >
                      {month.month_title}
                    </h3>
                    <p>{month.subtitle}</p>
                    <h4>{month.cardTitle}</h4>
                  </div>
                  <div
                    className={`inner_nav ${
                      month.month_title === "1 MONTH" &&
                      month.subtitle === "baby's life"
                        ? "birth"
                        : ""
                    }`}
                    style={getInnerNavStyle(monthIndex)}
                  >
                    <div
                      className={`slider-direction ${
                        openMonth === month.month_title ? "open" : ""
                      }`}
                      style={getAfterSliderContent(monthIndex)}
                    ></div>
                    {month.weeks &&
                      month.weeks.map((week, weekIndex) => (
                        <div key={weekIndex}>
                          <h5
                            className={`${
                              activeWeek === week.title &&
                              openSub === month.subtitle
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handleWeekClick(
                                month.month_title,
                                week.title,
                                week.content,
                                week.leap,
                                week.leap_intensity,
                                week.period,
                                week.leap_description,
                                week.development,
                                month.subtitle,
                                week.sleep_regression,
                                week.sleep_regression_text
                              )
                            }
                          >
                            {week.title}
                          </h5>
                        </div>
                      ))}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  }
);

export default BamofySlider;
