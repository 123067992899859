import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logoImage from "../../img/bamofy_logo.svg";
import "./Header.scss";
import Language from "./Language";

function Header(data) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const headerLinks = data.data.header_links[0];
  useEffect(() => {
    document.title = "Bamofy";
  }, []);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMobileMenu]);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <header>
      <div className="logo">
        <NavLink to="/">
          <img src={logoImage} alt="Bamofy" />
        </NavLink>
      </div>
      <nav>
        <div className={`menu ${showMobileMenu ? "active" : ""}`}>
          <ul>
            <li>
              <NavLink
                to="/"
                className={(navData) => (navData.isActive ? "active" : "none")}
                onClick={closeMobileMenu}
              >
                {headerLinks.home}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={(navData) => (navData.isActive ? "active" : "none")}
                onClick={closeMobileMenu}
              >
                {headerLinks.about_us}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/timeline"
                className={(navData) => (navData.isActive ? "active" : "none")}
                onClick={closeMobileMenu}
              >
              {headerLinks.timeline}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={(navData) => (navData.isActive ? "active" : "none")}
                onClick={closeMobileMenu}
              >
              {headerLinks.contact_us}
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <Language />
      <button 
      aria-label="menu-toggler"
        className={`mobile-menu-toggle ${showMobileMenu ? "active" : ""}`}
        onClick={toggleMobileMenu}
      ></button>
    </header>
  );
}

export default Header;
