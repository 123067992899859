import React from "react";
import {
  Edit,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';

function AboutEdit(props) {
  return (
    <Edit title="About Edit" {...props}>
      <SimpleForm fullWidth>
        <RichTextInput multiline source="about_title" fullWidth />
        <RichTextInput multiline source="about_subtitle" fullWidth />
        <ArrayInput source="about_pros" className="admin-weeks" label="">
          <SimpleFormIterator fullWidth className="admin-week">
            <RichTextInput
              source="pros_text"
              fullWidth
              multiline
              label="Pros Text"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <RichTextInput multiline source="about_footer" fullWidth />
      </SimpleForm>
    </Edit>
  );
}

export default AboutEdit;
